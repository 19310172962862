import { useQuery } from "@vue/apollo-composable";
import { AggregatedAreaDocument } from "~/graphql/generated/graphql";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
export var useAreaQuery = function useAreaQuery(variables) {
  var _useQueryFilters = useQueryFilters(),
    queryFilters = _useQueryFilters.value,
    setQueryFilters = _useQueryFilters.setValue;
  setQueryFilters("filters", function () {
    return {
      matches: [{
        portfolio_id: [Operator.Equals, variables.value.portfolioId]
      }]
    };
  }, {
    enabled: function enabled() {
      return !!variables.value.portfolioId;
    }
  });
  setQueryFilters("filters", function () {
    return {
      matches: [{
        property_id: [Operator.Equals, variables.value.propertyId]
      }]
    };
  }, {
    enabled: function enabled() {
      return !!variables.value.propertyId;
    }
  });
  var query = useQuery(AggregatedAreaDocument, function () {
    return {
      filters: queryFilters.value.filters
    };
  }, function () {
    return {
      enabled: !!variables.value.portfolioId || !!variables.value.propertyId
    };
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.aggregatedAssetManagementTenancyAreas[0];
  });
  return {
    loading: loading,
    result: result
  };
};